import "./news-list-item.scss";
import React from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import defaultImage from "@img/ic-empty-news.png";

import Link from "gatsby-link";

const NewsListItem = ({ pic, text, alt, path, publishedAt, className }) => {
	const dt = DateTime.fromISO(publishedAt)
		.setLocale("ru")
		.toLocaleString(DateTime.DATE_MED);
	return (
		<section className={cn("news-list-item", className)}>
			<Link to={path}>
				<img
					className="news-list-item__pic"
					src={pic || defaultImage}
					alt={alt}
				/>
				<div className="news-list-item__date">{dt}</div>
				<p className="news-list-item__text">{text}</p>
			</Link>
		</section>
	);
};

export default NewsListItem;
