import React, { useEffect, useState, useMemo } from "react";
import { Router } from "@reach/router";
import { DateTime } from "luxon";
import defaultImage from "@img/ic-empty-news.png";
import Markdown from "@components/markdown";
import SocialShare from "@components/social-share";
import BottomLineWithLink from "@components/bottom-line-with-link";
import { cms as axios } from "@core/axios";

import "@pages/about/article-template.scss";
import { cmsServer } from "@constants/common";
import NewsPage from "@page-contents/news";
import Helmet from "react-helmet";

const ArticleTemplate = props => {
	const { location, id } = props;
	const [collection, setCollection] = useState([]);

	const data = useMemo(() => {
		return collection.find(article => article._id === id);
	}, [collection, id]);

	const nextData = useMemo(() => {
		if (collection) {
			const index = collection.indexOf(data);
			return index >= 0 && index < collection.length - 1
				? collection[index + 1]
				: undefined;
		}
	}, [collection, data]);

	useEffect(() => {
		const getArticles = async () => {
			if (!collection.length) {
				const news = await axios(`/news?_sort=date:DESC`);
				setCollection(news.data);
			}
		};
		getArticles();
	}, [id, collection]);

	const getDate = date => {
		return DateTime.fromISO(date)
			.setLocale("ru")
			.toLocaleString(DateTime.DATE_MED);
	};

	const getPicture = image =>
		image && image.url ? `${cmsServer}${image.url}` : undefined;

	return data ? (
		<>
			<Helmet title={`${data.title}`} />
			<section className="full-width news-template">
				<div className="grid-container">
					<div className="row">
						<div className="news-template__left-col col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
							<img
								src={getPicture(data.image) || defaultImage}
								alt="Изображение для новости"
								className="hidden-md hidden-sm news-template__image"
							/>
							<h2 className="news-template__header">{data.title}</h2>
							<div className="news-template__date">{getDate(data.date)}</div>
							<Markdown source={data.content} />
						</div>

						<div className="news-template__right-col col-xs-12 col-md-offset-1 col-md-1 col-sm-offset-1 col-sm-10">
							<SocialShare
								image={
									getPicture(data.image) || `${location.origin}/${defaultImage}`
								}
								title={data.title}
								url={location.href}
							/>
						</div>
					</div>
				</div>
			</section>
			{nextData && (
				<BottomLineWithLink
					to={`/about/news/${nextData._id}`}
					title="Следующая новость"
				/>
			)}
		</>
	) : (
		<section className="full-width news-template">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						Указанная новость не найдена
					</div>
				</div>
			</div>
		</section>
	);
};

export default props => (
	<Router>
		<ArticleTemplate path="/about/news/:id" location={props.location} />
		<NewsPage path="/about/news" />
	</Router>
);
