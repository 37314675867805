import React, { useEffect, useState } from "react";
import Link from "gatsby-link";
import "./news.scss";
import DateTime from "luxon/src/datetime";
import NewsListItem from "@page-contents/index/news-list-item";
import defaultImage from "@img/ic-empty-news.png";
import ShowMore from "@components/show-more";
import { cms as axios } from "@core/axios";
import { cmsServer } from "@constants/common";

const News = () => {
	const [data, setData] = useState([]);

	const getArticles = async () => {
		const responseNews = await axios("/news?_sort=date:DESC");
		setData(responseNews.data);
	};

	useEffect(() => {
		getArticles();
	}, []);

	return data && data.length ? (
		<section className="full-width news">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Новости</h2>
					</div>
				</div>
				<div className="row hidden-xs">
					<ShowMore>
						{data.map((article, index) => {
							const { _id, date, title, image } = article;
							const pic =
								image && image.url ? `${cmsServer}${image.url}` : undefined;
							const dt = DateTime.fromISO(date)
								.setLocale("ru")
								.toLocaleString(DateTime.DATE_MED);
							return (
								<div
									key={index}
									className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10"
								>
									<Link className="news__item" to={`/about/news/${_id}`}>
										<div className="news__item__img">
											<img
												alt="Изображение к новости"
												src={pic || defaultImage}
											/>
										</div>
										<div className="news__item__content">
											<div className="news__item__content__block">
												<h4 className="news__item__content__block__title">
													{title}
												</h4>
												<div className="news__item__content__block__date">
													{dt}
												</div>
											</div>
											<div className="news__item__content__block">
												<div className="news__item__content__block__link">
													Читать
												</div>
											</div>
										</div>
									</Link>
								</div>
							);
						})}
					</ShowMore>
				</div>
				<div className="row hidden-md hidden-sm">
					<ShowMore buttonClassName="news__button-show-more">
						{data.map((article, index) => {
							const { _id, date, title, image } = article;
							const pic =
								image && image.url ? `${cmsServer}${image.url}` : undefined;
							return (
								<div className="col-xs-12 col-sm-6 col-md-3" key={index}>
									<NewsListItem
										pic={pic || defaultImage}
										text={title}
										path={`/about/news/${_id}`}
										publishedAt={date}
										alt="Изображение"
										className="news-list__item"
									/>
								</div>
							);
						})}
					</ShowMore>
				</div>
			</div>
		</section>
	) : null;
};

export default News;
